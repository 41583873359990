<template>
  <div>
    <b-overlay
      :show="$apollo.loading"
      rounded="sm"
    >
      <b-card
        class="mt-2 text-left"
      >
        <b-card-title>
          Manage Applicants
        </b-card-title>
        <div class="d-flex filters-box">
          <div class="fl-col-1">
            <span>Filters</span>
          </div>
          <div class="fl-col-2 d-flex justify-content-start">
            <b-navbar-nav class="nav align-items-start mr-50">
              <b-nav-item-dropdown
                class="dropdown-user nomarker"
                left
                toggle-class="d-flex align-items-center dropdown-user-link"
              >
                <template #button-content>
                  <b-button
                    :variant="filters[0].selected ? 'primary' : 'outline-primary'"
                    class="d-flex align-items-center py-50 px-1"
                    pill
                    @click="filters[0].open=!filters[0].open"
                  >
                    <span>{{ filters[0].title }}</span>
                    <div
                      v-if="filters[0].selected != null"
                      :class="`${filters[0].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                      style="border-radius:50px; font-weight: bolder;"
                    >
                      {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
                    </div>
                    <div
                      v-else
                      class="text-white py-25"
                      style="border-radius:50px; font-weight: bolder;"
                    >.</div>
                    <feather-icon
                      :icon="filters[0].open?'ChevronUpIcon':'ChevronDownIcon'"
                      class="ml-50"
                    />
                  </b-button>
                </template>

                <div
                  class="custom-search px-1 pt-1"
                  style="cursor: pointer;"
                  @click="filters[0].selected=null"
                >
                  <h6 class="text-primary">
                    -- No Filter --
                  </h6>
                </div>
                <div
                  v-for="round in filterrounds"
                  :key="round.id"
                  class="custom-search px-1 pt-50"
                >
                  <b-form-group
                    class="mb-0"
                  >
                    <b-form-checkbox
                      :checked="filters[0].selected == round.id"
                      @change="selectFilter(0, 'Single', round.id)"
                    >
                      {{ round.title }}
                    </b-form-checkbox>
                  </b-form-group>
                </div>
              </b-nav-item-dropdown>
            </b-navbar-nav>
            <!--            <b-navbar-nav class="nav align-items-start mr-50">-->
            <!--              <b-nav-item-dropdown-->
            <!--                class="dropdown-user nomarker"-->
            <!--                left-->
            <!--                toggle-class="d-flex align-items-center dropdown-user-link"-->
            <!--              >-->
            <!--                <template #button-content>-->
            <!--                  <b-button-->
            <!--                    :variant="filters[1].selected ? 'primary' : 'outline-primary'"-->
            <!--                    class="d-flex align-items-center py-50 px-1"-->
            <!--                    pill-->
            <!--                    @click="filters[1].open=!filters[1].open"-->
            <!--                  >-->
            <!--                    <span>{{ filters[1].title }}</span>-->
            <!--                    <div-->
            <!--                      v-if="filters[1].selected != null"-->
            <!--                      :class="`${filters[1].selected ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"-->
            <!--                      style="border-radius:50px; font-weight: bolder;"-->
            <!--                    >-->
            <!--                      {{ filters[1].selected.length ? filters[1].selected.length : '1' }}-->
            <!--                    </div>-->
            <!--                    <div-->
            <!--                      v-else-->
            <!--                      class="text-white py-25"-->
            <!--                      style="border-radius:50px; font-weight: bolder;"-->
            <!--                    >.</div>-->
            <!--                    <feather-icon-->
            <!--                      :icon="filters[1].open?'ChevronUpIcon':'ChevronDownIcon'"-->
            <!--                      class="ml-50"-->
            <!--                    />-->
            <!--                  </b-button>-->
            <!--                </template>-->

            <!--                <div-->
            <!--                  class="custom-search px-1 pt-1"-->
            <!--                  style="cursor: pointer;"-->
            <!--                  @click="filters[1].selected=null"-->
            <!--                >-->
            <!--                  <h6 class="text-primary">-->
            <!--                    &#45;&#45; No Filter &#45;&#45;-->
            <!--                  </h6>-->
            <!--                </div>-->
            <!--                <div-->
            <!--                  class="custom-search px-1 pt-50"-->
            <!--                >-->
            <!--                  <b-form-group-->
            <!--                    class="mb-0"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      :checked="filters[1].selected && filters[1].selected.indexOf('accepted') >= 0"-->
            <!--                      @change="selectFilter(1, 'Multiple', 'accepted')"-->
            <!--                    >-->
            <!--                      Accepted-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-form-group>-->
            <!--                </div>-->
            <!--                <div-->
            <!--                  class="custom-search px-1 pt-50"-->
            <!--                >-->
            <!--                  <b-form-group-->
            <!--                    class="mb-0"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      :checked="filters[1].selected && filters[1].selected.indexOf('declined') >= 0"-->
            <!--                      @change="selectFilter(1, 'Multiple', 'declined')"-->
            <!--                    >-->
            <!--                      Declined-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-form-group>-->
            <!--                </div>-->
            <!--                <div-->
            <!--                  class="custom-search px-1 pt-50"-->
            <!--                >-->
            <!--                  <b-form-group-->
            <!--                    class="mb-0"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      :checked="filters[1].selected && filters[1].selected.indexOf('inprocess') >= 0"-->
            <!--                      @change="selectFilter(1, 'Multiple', 'inprocess')"-->
            <!--                    >-->
            <!--                      In-Process-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-form-group>-->
            <!--                </div>-->
            <!--                <div-->
            <!--                  class="custom-search px-1 pt-50"-->
            <!--                >-->
            <!--                  <b-form-group-->
            <!--                    class="mb-0"-->
            <!--                  >-->
            <!--                    <b-form-checkbox-->
            <!--                      :checked="filters[1].selected && filters[1].selected.indexOf('onhold') >= 0"-->
            <!--                      @change="selectFilter(1, 'Multiple', 'onhold')"-->
            <!--                    >-->
            <!--                      On-Hold-->
            <!--                    </b-form-checkbox>-->
            <!--                  </b-form-group>-->
            <!--                </div>-->
            <!--              </b-nav-item-dropdown>-->
            <!--            </b-navbar-nav>-->
            <b-navbar-nav class="nav align-items-start mr-50">
              <div style="padding: 0.358rem 0rem;">
                <b-button
                  :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="fetchSortScore()"
                >
                  <span>{{ filters[2].title }}</span>
                  <div
                    :class="`${filters[2].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    <feather-icon
                      :icon="filters[2].selected?'XIcon':'CheckIcon'"
                    />
                  </div>
                </b-button>
              </div>
            </b-navbar-nav>
            <b-navbar-nav class="nav align-items-start mr-50">
              <div style="padding: 0.358rem 0rem;">
                <b-button
                  :variant="filters[3].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="fetchSortVerdict()"
                >
                  <span>{{ filters[3].title }}</span>
                  <div
                    :class="`${filters[3].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    <feather-icon
                      :icon="filters[3].selected?'XIcon':'CheckIcon'"
                    />
                  </div>
                </b-button>
              </div>
            </b-navbar-nav>
            <b-navbar-nav
              v-if="filters[2].selected && filters[3].selected"
              class="nav align-items-start mr-50"
            >
              <div style="padding: 0.358rem 0rem;">
                <b-button
                  variant="flat-primary"
                  class="d-flex align-items-center py-50 px-1"
                >
                  <span>{{ filters[4].title }}</span>
                  <div class="d-flex align-items-center">
                    <b-form-select
                      v-model="filters[4].selected"
                      class="px-0 py-0 pl-50 text-primary"
                      style="background: none; box-shadow: none; border: none; height: auto;"
                      :options="[10,20,30,40,50,60,70,80,90,100]"
                    />%
                  </div>
                </b-button>
              </div>
            </b-navbar-nav>
            <!-- <div class="filter-holder">
            </div> -->
          </div>
          <div class="fl-col-3">
            <b-button
              variant="primary"
              size="sm"
              @click="openAdvanceModal()"
            >
              Advance
            </b-button>
          </div>
        </div>
        <!-- search input -->
        <div class="d-flex justify-content-between align-items-center">
          <div class="custom-search d-flex justify-content-start mt-2">
            <b-form-group
              class="form-inline"
              label="Search"
              label-size="sm"
            >
              <b-form-input
                v-model="searchTerm"
                class="d-inline-block mr-1"
                placeholder="Search applicants"
                type="text"
              />
            </b-form-group>
          </div>
          <div
            v-if="filters[0].selected"
            class="d-flex pt-1"
          >
            <b-form-select
              v-model="bulkStatus"
              :options="[{ value: null, text: 'Select Status', disabled: true }, 'Promoted', 'Declined']"
            />
            <b-button
              variant="primary"
              class="ml-1"
              :disabled="!bulkStatus"
              @click="updateStatus()"
            >
              Update
            </b-button>
          </div>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :pagination-options="{
            enabled: true,
            perPage:pageLength,
            position: 'top'
          }"
          :rows="filteredRows"
          :select-options="{
            enabled: filters[0].selected ? true : false,
            selectOnCheckboxOnly: true,
          }"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          @on-selected-rows-change="selectionChanged"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >

            <!-- Column: Status -->
            <span
              v-if="props.column.id === 'round' && props.row.programs_applicationformstable"
            ><b-badge
               v-b-tooltip="'Round Number'"
               class="mr-50 mb-25"
               variant="primary"
             >{{ props.row.programs_applicationformstable.round_number }}</b-badge>
              {{ props.formattedRow[props.column.field] }}
            </span>

            <!-- Column: Status-->
            <span
              v-else-if="props.column.field === 'organization_name' && props.row.users_organizationtable"
              style="display: flex; justify-content: space-between;"
            >
              <span>
                {{ props.row.users_organizationtable.title }}
              </span>
              <b-badge
                variant="light-primary"
              >
                {{ props.row.users_organizationtable.type }}
              </b-badge>
            </span>

            <span
              v-else-if="props.column.field === 'organization_name' && !props.row.users_organizationtable"
              style="display: flex; justify-content: flex-end;"
            >
              <b-badge
                variant="light-primary"
              >
                {{ props.row.type }}
              </b-badge>
            </span>

            <span v-else-if="props.column.field === 'status'">
              <b-badge
                :variant="statusVariant(props.row.status)"
              >
                {{ props.row.status || 'In-Process' }}
              </b-badge>
            </span>

            <!-- Column: Action -->
            <span v-else-if="props.column.field === 'action'">
              <b-button
                v-if="!filters[0].selected"
                :to="{name: 'competitions-view-applications', params: {pid: $route.params.id, aid:props.row.id }}"
                size="sm"
                variant="outline-primary"
              >View Applications</b-button>
              <b-button
                v-if="filters[0].selected"
                :to="{name: 'application-question', params: {pid: $route.params.id, aid: props.row.users_organizationtable.id, apid: filters[0].selected}, query: {readonly: 'true'}}"
                size="sm"
                variant="primary"
                class="mr-1"
              >Open</b-button>
              <b-button
                v-if="filters[0].selected"
                :to="{name: 'view-evaluation', params: {pid: $route.params.id, aid: props.row.id, apid: filters[0].selected }}"
                size="sm"
                variant="outline-primary"
              >View Evaluation</b-button>
            </span>

            <!-- Column: Common -->
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
          <template
            slot="column-filter"
            slot-scope="props"
          >
            <span v-if="props.column.id === 'round'"><b-form-select
              v-if="props.column.hasOwnProperty('filterOptions') && props.column.filterOptions.customFilter"
              v-model="filter.round"
              :options="[{text: '-- No Filter --', value: null}, ...roundFilterOptions]"
              placeholder="-- No Filter --"
            /></span>
            <span v-if="props.column.field === 'status'"><b-form-select
              v-if="props.column.hasOwnProperty('filterOptions') && props.column.filterOptions.customFilter"
              v-model="filter.status"
              :options="[{text: '-- No Filter --', value: null}, {text: 'Accepted', value: 'accepted'}, {text: 'Declined', value: 'declined'}, {text: 'In-Process', value: 'in-process'}, {text: 'On-Hold', value: 'on-hold'}]"
              placeholder="-- No Filter --"
            /></span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-top"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mb-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['10','20','30']"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of <span class="font-medium-4 ml-25"><b-badge
                  variant="light-primary"
                >{{
                  props.total
                }} entries
                </b-badge></span></span>

              </div>
              <div>
                <b-pagination
                  :per-page="pageLength"
                  :total-rows="props.total"
                  :value="1"
                  align="right"
                  class="mt-50"
                  first-number
                  last-number
                  next-class="next-item"
                  prev-class="prev-item"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card>
    </b-overlay>
    <b-modal
      id="appModal"
      ok-only
      size="lg"
      title="Application Form"
    >
      <form-builder
        v-if="selectedRound !== null"
        v-model="rounds[selectedRound].programs_applicationsquestionstables"
        :inherited-sections="rounds[selectedRound].programs_applicationsquestionstables"
      />
    </b-modal>
    <b-modal
      id="filterModal"
      ok-only
      no-close-on-backdrop
      size="lg"
      title="Advance Filters"
    >
      <h5><strong>Filters</strong></h5>
      <b-row>
        <b-col
          md="4"
          style="border-right: solid 1px #ebe9f1"
          class="pr-0"
        >
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[0].selected === null ? 'py-75' : 'py-50'} ${filters[0].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="advanceSelector(0)"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[0].title }}</span>
              <div
                v-if="filters[0].selected != null"
                :class="`${filters[0].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[0].selected.length ? filters[0].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <!--          <h6-->
          <!--            :class="`d-flex align-items-center mb-0 ${filters[1].selected === null ? 'py-75' : 'py-50'} ${filters[1].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"-->
          <!--            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"-->
          <!--            @click="advanceSelector(1)"-->
          <!--          >-->
          <!--            <div class="d-flex align-items-center">-->
          <!--              <span>{{ filters[1].title }}</span>-->
          <!--              <div-->
          <!--                v-if="filters[1].selected != null"-->
          <!--                :class="`${filters[1].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"-->
          <!--                style="border-radius:50px; font-weight: bolder;"-->
          <!--              >-->
          <!--                {{ filters[1].selected.length ? filters[1].selected.length : '1' }}-->
          <!--              </div>-->
          <!--            </div>-->
          <!--            <feather-icon-->
          <!--              icon="ChevronRightIcon"-->
          <!--              class="ml-50"-->
          <!--            />-->
          <!--          </h6>-->
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[7].selected === null ? 'py-75' : 'py-50'} ${filters[7].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="fetchSort()"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[7].title }}</span>
              <div
                v-if="filters[7].selected != null"
                :class="`${filters[7].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[7].selected.length ? filters[7].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[5].selected === null ? 'py-75' : 'py-50'} ${filters[5].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="fetchJury()"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[5].title }}</span>
              <div
                v-if="filters[5].selected != null"
                :class="`${filters[5].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[5].selected.length ? filters[5].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
          <h6
            :class="`d-flex align-items-center mb-0 ${filters[6].selected === null ? 'py-75' : 'py-50'} ${filters[6].open ? 'bg-primary text-white px-1 justify-content-between' : 'px-1'}`"
            style="cursor: pointer; border-bottom: solid 1px #ebe9f1; border-top: solid 1px #ebe9f1"
            @click="fetchResponses()"
          >
            <div class="d-flex align-items-center">
              <span>{{ filters[6].title }}</span>
              <div
                v-if="filters[6].selected != null"
                :class="`${filters[6].open ? 'bg-white text-primary' : 'bg-primary text-white'} ml-50 px-50 py-25`"
                style="border-radius:50px; font-weight: bolder;"
              >
                {{ filters[6].selected.length ? filters[6].selected.length : '1' }}
              </div>
            </div>
            <feather-icon
              icon="ChevronRightIcon"
              class="ml-50"
            />
          </h6>
        </b-col>
        <b-col md="8">
          <div
            :style="`height: ${filters[0].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[0].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="round in filterrounds"
              :key="round.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[0].selected == round.id"
                  @change="selectFilter(0, 'Single', round.id)"
                >
                  {{ round.title }}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            :style="`height: ${filters[1].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[1].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[1].selected && filters[1].selected.indexOf('accepted') >= 0"
                  @change="selectFilter(1, 'Multiple', 'accepted')"
                >
                  Accepted
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[1].selected && filters[1].selected.indexOf('declined') >= 0"
                  @change="selectFilter(1, 'Multiple', 'declined')"
                >
                  Declined
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[1].selected && filters[1].selected.indexOf('inprocess') >= 0"
                  @change="selectFilter(1, 'Multiple', 'inprocess')"
                >
                  In-Process
                </b-form-checkbox>
              </b-form-group>
            </div>
            <div
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[1].selected && filters[1].selected.indexOf('onhold') >= 0"
                  @change="selectFilter(1, 'Multiple', 'onhold')"
                >
                  On-Hold
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div
            v-if="filterjury.length > 0"
            :style="`height: ${filters[5].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[5].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div
              v-for="jury in filterjury[0].programs_applicationscorestables"
              :key="jury.id"
              class="custom-search px-1 pt-50"
            >
              <b-form-group
                class="mb-0"
              >
                <b-form-checkbox
                  :checked="filters[5].selected && filters[5].selected.indexOf(jury) >= 0"
                  @change="selectFilter(5, 'Multiple', jury)"
                >
                  {{ jury.programs_partnerstable.users_customuser.full_name }}
                </b-form-checkbox>
              </b-form-group>
            </div>
            <h5 class="text-primary mt-1 px-1">
              Showing {{ filteredRows.length }} out of {{ getRoundLength() }} Startup{{ getRoundLength() === 1 ? '' : 's' }}
            </h5>
          </div>
          <div
            v-if="filterresponse.length > 0"
            :style="`height: ${filters[6].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <div
              v-if="filters[6].selected"
              class="custom-search px-1"
              style="cursor: pointer;"
              @click="filters[6].selected=null"
            >
              <h6 class="text-primary">
                -- No Filter --
              </h6>
            </div>
            <div>
              <b-row
                v-for="(response, key) in filters[6].selected"
                :key="key"
                class="mb-1"
              >
                <b-col md="9">
                  <v-select
                    v-model="response.question"
                    :options="filterresponse[0].programs_applicationsquestionstables.filter(resp => !filters[6].selected.map(data => data.question).includes(resp))"
                    :get-option-label="option => option.question"
                    append-to-body
                    class="w-100"
                    placeholder="Select Question"
                    @input="response.responses = null"
                  />
                  <v-select
                    v-if="response.question"
                    v-model="response.responses"
                    :options="response.question.programs_applicationresponsetables"
                    :get-option-label="option => option.response"
                    multiple
                    append-to-body
                    class="w-100"
                    placeholder="Select Responses"
                  />
                </b-col>
                <b-col md="3">
                  <b-button
                    block
                    variant="outline-danger"
                    @click="removeResponseFilter(key)"
                  >
                    Remove
                  </b-button>
                </b-col>
              </b-row>
            </div>
            <div>
              <h6>Add New</h6>
              <b-row>
                <b-col md="9">
                  <v-select
                    v-model="newquestion"
                    :options="filters[6].selected ? filterresponse[0].programs_applicationsquestionstables.filter(resp => !filters[6].selected.map(data => data.question).includes(resp)) : filterresponse[0].programs_applicationsquestionstables"
                    :get-option-label="option => option.question"
                    append-to-body
                    class="w-100"
                    placeholder="Select Question"
                    @input="newresponses = null"
                  />
                  <v-select
                    v-if="newquestion"
                    v-model="newresponses"
                    :options="newquestion.programs_applicationresponsetables"
                    :get-option-label="option => option.response"
                    multiple
                    append-to-body
                    class="w-100"
                    placeholder="Select Responses"
                  />
                </b-col>
                <b-col md="3">
                  <b-button
                    block
                    variant="primary"
                    @click="addNewResponseFilter(newquestion, newresponses)"
                  >
                    <feather-icon
                      icon="PlusIcon"
                    />
                    <span>Add</span>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
          <div
            v-if="filterresponse.length > 0"
            :style="`height: ${filters[7].open ? 'auto' : '1px' }; overflow: hidden;`"
          >
            <h6>Select Sorting</h6>
            <div class="nav align-items-start mr-50">
              <div style="padding: 0.358rem 0rem;">
                <b-button
                  :variant="filters[2].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[2].selected=!filters[2].selected"
                >
                  <span>{{ filters[2].title }}</span>
                  <div
                    :class="`${filters[2].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    <feather-icon
                      :icon="filters[2].selected?'XIcon':'CheckIcon'"
                    />
                  </div>
                </b-button>
              </div>
            </div>
            <div class="nav align-items-start mr-50">
              <div style="padding: 0.358rem 0rem;">
                <b-button
                  :variant="filters[3].selected ? 'primary' : 'outline-primary'"
                  class="d-flex align-items-center py-50 px-1"
                  pill
                  @click="filters[3].selected=!filters[3].selected"
                >
                  <span>{{ filters[3].title }}</span>
                  <div
                    :class="`${filters[3].selected ? 'bg-white text-primary px-50' : 'text-primary'} ml-50 py-25`"
                    style="border-radius:50px; font-weight: bolder;"
                  >
                    <feather-icon
                      :icon="filters[3].selected?'XIcon':'CheckIcon'"
                    />
                  </div>
                </b-button>
              </div>
            </div>
            <div
              v-if="filters[2].selected && filters[3].selected"
              class="nav align-items-start mr-50"
            >
              <div style="padding: 0.358rem 0rem;">
                <b-button
                  variant="outline-primary"
                  class="d-flex align-items-center py-50 px-1"
                >
                  <span>{{ filters[4].title }}</span>
                  <div class="d-flex align-items-center">
                    <b-form-select
                      v-model="filters[4].selected"
                      class="px-0 py-0 pl-50 text-primary"
                      style="background: none; box-shadow: none; border: none; height: auto;"
                      :options="[10,20,30,40,50,60,70,80,90,100]"
                    />%
                  </div>
                </b-button>
              </div>
            </div>
            <div
              class="px-1 py-1 rounded mt-50"
              style="background-color: #EEEDFD;"
            >
              Verdict is the judgement given by the jury and scores are points given to each response. To sort applicant based on both verdict and score, weightage can be adjusted by clickiung on weightage percentage under verdict.<br>
              <strong>Note: </strong> if only verdict or only score is selected they will carry 100% weightage for sorting.
            </div>
          </div>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>
<script>
import FormBuilder from '@/views/incubator-portal/programs/components/FormBuilder.vue'
import {
  BBadge,
  BButton,
  BCard,
  BCardTitle,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BModal,
  BPagination,
  BNavItemDropdown,
  BNavbarNav,
  BFormCheckbox,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
import { VueGoodTable } from 'vue-good-table'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BButton,
    BFormInput,
    BCardTitle,
    BCard,
    BFormGroup,
    VueGoodTable,
    BPagination,
    BFormSelect,
    BBadge,
    BModal,
    FormBuilder,
    BNavItemDropdown,
    BNavbarNav,
    BFormCheckbox,
    vSelect,
  },
  data() {
    return {
      mutationLoading: false,
      open: false,
      isFiltered: false,
      filters: [
        {
          open: false,
          selected: null,
          title: 'Shortlisting Rounds',
        },
        {
          open: false,
          selected: null,
          title: 'Status',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Score',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By Verdict',
        },
        {
          open: false,
          selected: 50,
          title: 'Weightage of Verdict :',
        },
        {
          open: false,
          selected: null,
          title: 'Jury',
        },
        {
          open: false,
          selected: null,
          title: 'Application Response',
        },
        {
          open: false,
          selected: null,
          title: 'Sort By',
        },
      ],
      selectedRound: null,
      pageLength: 20,
      filter: {
        round: null,
        status: null,
      },
      columns: [
        {
          label: 'Full Name',
          field(row) {
            if (!row.users_customuser) return '-'
            return row.users_customuser.full_name
          },
        },
        {
          label: 'Organization Name',
          field: 'organization_name',
        },
        {
          id: 'round',
          label: 'Shortlisting Round',
          field(row) {
            if (!row.programs_applicationformstable) return ''
            return row.programs_applicationformstable.title
          },
          filterOptions: {
            enabled: true, // enable filter for this column
            customFilter: true, // use custom filter component
          },
        },
        // {
        //   label: 'Status',
        //   field: 'status',
        //   sortable: false,
        //
        // },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      newquestion: null,
      newresponses: null,
      bulkStatus: null,
      rounds: [],
      selectedRows: [],
      filterrounds: [],
      filterjury: [],
      filterresponse: [],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        draft: 'light-primary',
        enabled: 'light-success',
        disabled: 'light-secondary',
        inprocess: 'light-primary',
        accepted: 'light-success',
        declined: 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filteredRows() {
      // console.log(this.rows)
      const temp = []
      let insert = true
      this.rows.map(data => {
        insert = true
        if (this.filters[6].selected) {
          if (data.programs_applicationresponsetables) {
            this.filters[6].selected.map(filter => {
              if (data.programs_applicationresponsetables.map(response => response.programs_applicationsquestionstable.id).includes(filter.question.id)) {
                insert = true
              } else {
                insert = false
                return 0
              }
              return 0
            })
          } else {
            insert = false
          }
        }
        // if (!this.filters[5].selected && !this.filters[6].selected) {
        //   temp.push(data)
        // }
        if (insert) {
          temp.push(data)
        }
        return 0
      })
      // console.log(temp)
      if (temp.length > 0 && temp[0].programs_applicationscorestables_aggregate) {
        if (this.filters[2].selected && !this.filters[3].selected) {
          temp.sort((a, b) => b.programs_applicationscorestables_aggregate.aggregate.avg.direct_score - a.programs_applicationscorestables_aggregate.aggregate.avg.direct_score)
        }
        if (this.filters[3].selected && !this.filters[2].selected) {
          temp.sort((a, b) => b.programs_applicationscorestables_aggregate.aggregate.avg.computed_verdict - a.programs_applicationscorestables_aggregate.aggregate.avg.computed_verdict)
        }
        if (this.filters[3].selected && this.filters[2].selected) {
          temp.sort((a, b) => ((b.programs_applicationscorestables_aggregate.aggregate.avg.computed_verdict * this.filters[4].selected) + (b.programs_applicationscorestables_aggregate.aggregate.avg.direct_score * (100 - this.filters[4].selected))) - ((a.programs_applicationscorestables_aggregate.aggregate.avg.computed_verdict * this.filters[4].selected) + (a.programs_applicationscorestables_aggregate.aggregate.avg.direct_score * (100 - this.filters[4].selected))))
        }
      }
      return temp
    },
    roundFilterOptions() {
      return this.rows.filter(e => e.programs_applicationformstable)
        .sort((a, b) => a.programs_applicationformstable.round_number - b.programs_applicationformstable.round_number)
        .map(row => ({
          text: `Round ${row.programs_applicationformstable.round_number} - ${row.programs_applicationformstable.title}`,
          value: row.programs_applicationformstable.round_number,
        }))
        .filter((e, i, a) => a.findIndex(t => t.value === e.value) === i) // Remove duplicates
    },
  },
  methods: {
    selectionChanged(e) {
      this.selectedRows = e.selectedRows
    },
    addRound() {
      this.rounds.push({
        title: 'New Round',
        round_number: this.rounds.length + 1,
        status: 'draft',
        review_status: 'draft',
        program_id: this.$route.params.id,
        programs_applicationsquestionstables: [],
      })
    },
    advanceSelector(index) {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[index].open = true
    },
    openAdvanceModal() {
      for (let i = 0; i < this.filters.length; i += 1) {
        this.filters[i].open = false
      }
      this.filters[0].open = true
      this.$bvModal.show('filterModal')
    },
    getVerdictName(val) {
      if (val >= 8.5 && val <= 10) {
        return 'Excellent'
      }
      if (val >= 7 && val < 8.5) {
        return 'Good'
      }
      if (val >= 5 && val < 7) {
        return 'Satisfactory'
      }
      return 'Unsatisfactory'
    },
    selectFilter(index, mode, value) {
      if (mode === 'Single') {
        if (this.filters[index].selected === value) {
          this.filters[index].selected = null
        } else {
          this.filters[index].selected = value
        }
      } else if (mode === 'Multiple') {
        if (this.filters[index].selected !== null && this.filters[index].selected.indexOf(value) >= 0) {
          const temp = this.filters[index].selected
          temp.splice(temp.indexOf(value), 1)
          this.filters[index].selected = temp
          if (temp.length === 0) {
            this.filters[index].selected = null
          }
        } else {
          const temp = this.filters[index].selected ? this.filters[index].selected : []
          temp.push(value)
          this.filters[index].selected = temp
        }
      }
    },
    addNewResponseFilter(newquestion, newresponses) {
      const temp = this.filters[6].selected ? this.filters[6].selected : []
      temp.push({
        question: newquestion,
        responses: newresponses,
      })
      this.filters[6].selected = temp
      this.newquestion = null
      this.newresponses = null
    },
    removeResponseFilter(index) {
      const temp = []
      this.filters[6].selected.map((fltr, fkey) => {
        if (fkey !== index) {
          temp.push(fltr)
        }
        return 0
      })
      this.filters[6].selected = temp
    },
    fetchJury() {
      if (this.filters[2].selected || this.filters[3].selected) {
        this.advanceSelector(5)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select sort by',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    fetchResponses() {
      if (this.filters[0].selected) {
        this.advanceSelector(6)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a round',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    fetchSort() {
      if (this.filters[0].selected) {
        this.advanceSelector(7)
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a round',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    fetchSortScore() {
      if (this.filters[0].selected) {
        this.filters[2].selected = !this.filters[2].selected
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a round',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    fetchSortVerdict() {
      if (this.filters[0].selected) {
        this.filters[3].selected = !this.filters[3].selected
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select a round',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
    getRoundLength() {
      if (this.filters[0].selected) {
        return this.filterrounds.filter(round => round.id === this.filters[0].selected)[0].programs_applicantstables_aggregate.aggregate.count
      }
      return this.filteredRows.length
    },
    updateApplication(i) {
      const payload = this.rounds[i]

      if (payload.programs_applicationsquestionstables.length) {
        payload.programs_applicationsquestionstables = {
          data: payload.programs_applicationsquestionstables,
          on_conflict: {
            constraint: 'programs_applicationsquestionstable_pkey',
            update_columns: ['question', 'input_type', 'options', 'section'],
          },
        }
      } else {
        delete payload.programs_applicationsquestionstables
      }
      this.mutationLoading = true
      this.$apollo.mutate(({
        mutation: gql`mutation($object: programs_applicationformstable_insert_input!) {
            insert_programs_applicationformstable_one(object: $object, on_conflict: {constraint: programs_applicationformstable_pkey, update_columns: title}) {
              id
            }
          }`,
        variables: {
          object: payload,
        },
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      }))
    },
    updateApplicationStatus(status, item, col) {
      const update = status === 'enabled' ? 'disabled' : 'enabled'
      this.mutationLoading = true
      this.$apollo.mutate({
        mutation: gql`mutation {
          update_programs_applicationformstable_by_pk(pk_columns: {id: ${item}}, _set: {${col}: ${update}}) {
            id
          }
        }`,
        update: () => {
          this.$apollo.queries.rounds.refetch()
          this.mutationLoading = false
        },
      })
    },
    getFilterStatus() {
      let string = ''
      if (this.filters[1].selected) {
        this.filters[1].selected.map(status => {
          if (string === '') {
            string = `"${status}"`
          } else {
            string = `${string}, "${status}"`
          }
          return 0
        })
      }
      return string
    },
    getFilterJury() {
      let string = ''
      if (this.filters[5].selected) {
        this.filters[5].selected.map(jury => {
          if (string === '') {
            string = `${jury.programs_partnerstable.id}`
          } else {
            string = `${string}, ${jury.programs_partnerstable.id}`
          }
          return 0
        })
      }
      return string
    },
    getFilterResponses() {
      let string = ''
      if (this.filters[6].selected) {
        this.filters[6].selected.map(response => {
          response.responses.map(newresp => {
            if (string === '') {
              string = `"${newresp.response}"`
            } else {
              string = `${string}, "${newresp.response}"`
            }
            return 0
          })
          return 0
        })
      }
      // console.log(string)
      return string
    },
    getFilterQuestions() {
      let string = ''
      if (this.filters[6].selected) {
        this.filters[6].selected.map(newresp => {
          if (string === '') {
            string = `"${newresp.question.id}"`
          } else {
            string = `${string}, "${newresp.question.id}"`
          }
          return 0
        })
      }
      // console.log(string)
      return string
    },
    getNextRoundId() {
      const crntIndex = this.filterrounds.map(round => round.id).indexOf(this.filters[0].selected)
      if (crntIndex >= 0 && crntIndex < (this.filterrounds.length - 1) && this.bulkStatus !== 'Declined') {
        return this.filterrounds[crntIndex + 1].id
      }
      return this.filters[0].selected
    },
    updateStatus() {
      this.mutationLoading = true
      let mutationObject = null
      if (this.selectedRows.length > 0) {
        mutationObject = this.selectedRows.map(mapped => ({
          current_application_form_id: this.getNextRoundId(),
          status: this.bulkStatus === 'Declined' ? 'declined' : 'inprocess',
          id: mapped.id,
          programs_applicantprogresstables: {
            data: {
              id: mapped.id,
              application_form_id: this.getNextRoundId(),
              status: this.bulkStatus === 'Declined' ? 'declined' : 'inprocess',
            },
            on_conflict: {
              constraint: 'programs_applicantprogresstable_pkey',
              update_columns: ['status'],
            },
          },
        }))
        this.$apollo.mutate(
          {
            mutation: gql`mutation MyMutation($object: [programs_applicantstable_insert_input!]!) {
            insert_programs_applicantstable(objects: $object, on_conflict: {constraint: programs_applicantstable_pkey, update_columns: [current_application_form_id, status]}) {
              affected_rows
            }
          }`,
            variables: {
              object: mutationObject,
            },
            update: (store, { data: { insert_programs_applicantstable } }) => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: insert_programs_applicantstable.affected_rows ? 'Updated successfully' : 'Failed to update',
                  icon: insert_programs_applicantstable.affected_rows ? 'CheckIcon' : 'XIcon',
                  variant: insert_programs_applicantstable.affected_rows ? 'success' : 'warning',
                },
              })
              this.mutationLoading = false
              this.$apollo.queries.rows.refetch()
            },
          },
        )
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please select at least 1 row',
            icon: 'XIcon',
            variant: 'danger',
          },
        })
      }
    },
  },
  apollo: {
    rows: {
      query() {
        return gql`
        query tableRows {
          programs_applicantstable(
            where: {
              ${this.filters[1].selected ? `status: {_in: [${this.getFilterStatus()}]},` : ''}
              ${this.filters[0].selected ? `current_application_form_id: {_eq: ${this.filters[0].selected}},` : ''}
              program_id: {_eq: ${this.$route.params.id}},
              ${this.filters[2].selected || this.filters[3].selected || this.filters[5].selected ? `
                programs_applicationscorestables_aggregate: {
                  count: {
                    predicate: {${this.filters[5].selected ? `_eq: ${this.filters[5].selected.length}` : '_neq: 0'}},
                    ${this.filters[5].selected ? `
                      filter: {partner_id: {_in: [${this.getFilterJury()}]}}
                    ` : ''}
                  }
                }
              ` : ''}
              ${this.filters[6].selected ? `
                programs_applicationresponsetables_aggregate: {
                  count: {
                    predicate: {_eq: ${this.filters[6].selected.length}},
                    filter: {
                      question_id: {_in: [${this.getFilterQuestions()}]},
                      response: {_in: [${this.getFilterResponses()}]}
                    }
                  }
                }
              ` : ''}
            },
            distinct_on: id
          ) {
            id
            users_organizationtable {
              id
              title
            }
            programs_applicationformstable {
              round_number
              title
            }
            ${this.filters[2].selected || this.filters[3].selected || this.filters[5].selected ? `
              programs_applicationscorestables_aggregate
              ${this.filters[5].selected ? `
                (where: { partner_id: {_in: [${this.getFilterJury()}]} })
              ` : ''}
              {
                aggregate {
                  avg {
                    direct_score
                    computed_verdict
                  }
                }
              }
            ` : ''}
            ${this.filters[6].selected ? `
              programs_applicationresponsetables(
                where: {
                  response: {_in: [${this.getFilterResponses()}]}
                }
              ) {
                response
                programs_applicationsquestionstable {
                  id
                }
              }
            ` : ''}
          }
        }`
      },
      update: data => data.programs_applicantstable,
    },
    filterrounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where: {program_id: {_eq: ${this.$route.params.id}}}, order_by: {id: asc}) {
            title
            id
            programs_applicantstables_aggregate {
              aggregate {
                count
              }
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    filterjury: {
      query() {
        return gql`
        {
          programs_applicationformstable(where: {id: {_eq: ${this.filters[0].selected}}}) {
            programs_applicationscorestables(distinct_on: partner_id) {
              programs_partnerstable {
                id
                users_customuser {
                  full_name
                }
                users_organizationtable {
                  title
                }
              }
              programs_applicantstable {
                id
              }
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    filterresponse: {
      query() {
        return gql`
        {
          programs_applicationformstable(where: {id: {_eq: ${this.filters[0].selected}}}) {
            programs_applicationsquestionstables(order_by: {id: asc}) {
              id
              question
              programs_applicationresponsetables(distinct_on: response, where: {response: {_nin: "null", _neq: "", _is_null: false}}) {
                response
              }
            }
          }
        }`
      },
      update: data => data.programs_applicationformstable,
    },
    rounds: {
      query() {
        return gql`
        {
          programs_applicationformstable(where:{program_id: {_eq: ${this.$route.params.id}}}, order_by: {round_number: asc}){
            id
            round_number
            title
            status
            review_status
            programs_applicationsquestionstables(order_by: {id: asc}) {
              id
              question
              section
              options
              input_type
              is_required
            }
          }
        }`
      },
      update(data) {
        /* eslint-disable no-param-reassign */
        if (data.programs_applicationformstable.length) {
          data.programs_applicationformstable.forEach(e => {
            if (e.status !== 'draft') {
              delete e.programs_applicationsquestionstables
            }
          })
        }
        return data.programs_applicationformstable
      },
    },
  },
}
</script>

<style>
  .dropdown-toggle::after {
    display:none;
  }
  .filters-box{
    border-top: solid 1px #d3d3d3;
    border-bottom: solid 1px #d3d3d3;
  }
  .fl-col-1{
    width:fit-content;
    padding-right: 1rem;
    display: flex;
    align-items: center;
  }
  .fl-col-2{
    width: 100%;
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    border-left: solid 1px #d3d3d3;
    border-right: solid 1px #d3d3d3;
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: auto;
    overflow-y: visible; */
  }
  .fl-col-2::-webkit-scrollbar {
    width: 0px;
    height: 0px;
  }
  .fl-col-2::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
  .fl-col-2::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
  .filter-holder{
    display: flex;
  }
  .fl-col-3{
    width:fit-content;
    padding-left: 1rem;
    display: flex;
    align-items: center;
  }
</style>
